//
// Examples
//

.docs-examples .img-thumbnail {
  margin-bottom: .75rem;
}
.docs-examples h4 {
  margin-bottom: .25rem;
}
.docs-examples p {
  margin-bottom: 1.25rem;
}

@media (max-width: 480px) {
  .docs-examples {
    margin-right: -.75rem;
    margin-left: -.75rem;
  }
  .docs-examples > [class^="col-"] {
    padding-right: .75rem;
    padding-left: .75rem;
  }
}
