// stylelint-disable declaration-no-important

//
// Right side table of contents
//

.docs-toc {
  @supports (position: sticky) {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

.section-nav {
  padding-left: 0;
  // border-left: 1px solid #eee;

  ul {
    padding-left: 1rem;

    ul {
      display: none;
    }
  }
}

.toc-entry {
  display: block;

  a {
    display: block;
    padding: .125rem 1.5rem;
    color: #99979c;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}

//
// Left side navigation
//

.docs-sidebar {
  order: 0;
  // background-color: #f5f2f9;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.docs-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.docs-search-docs-toggle {
  line-height: 1;
  color: $gray-900;
}

.docs-sidenav {
  display: none;
}

.docs-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, .65);

  &:hover {
    color: rgba(0, 0, 0, .85);
    text-decoration: none;
  }
}

.docs-toc-item {
  &.active {
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    > .docs-toc-link {
      color: rgba(0, 0, 0, .85);

      &:hover {
        background-color: transparent;
      }
    }

    > .docs-sidenav {
      display: block;
    }
  }
}

// All levels of nav
.docs-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, .65);
}

.docs-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, .85);
  text-decoration: none;
  background-color: transparent;
}

.docs-sidebar .nav > .active > a,
.docs-sidebar .nav > .active:hover > a {
  font-weight: 500;
  color: rgba(0, 0, 0, .85);
  background-color: transparent;
}
