// Wall of Browser Bugs
//
// Better display for the responsive table on the Wall of Browser Bugs.

.docs-browser-bugs {
  td p {
    margin-bottom: 0;
  }
  th:first-child {
    width: 18%;
  }
}
